import * as React from "react";
import { useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown";
import Boost from "highcharts/modules/boost";
import { Combobox } from "@headlessui/react";
import VirtualCombobox from "components/virtual-combobox";
import clsx from "clsx";

// TODO: Update the colors to match the email design (with order)
const COLORS = [
  "#dc3912",
  "#3366cc",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#b82e2e",
  "#316395",
];

function getInitialSeriesName(module) {
  switch (module) {
    case "default_aws_graph_sub_tag":
      return "Tags";
    case "default_aws_graph_product":
      return "Services";
    case "default_aws_graph_region":
      return "Regions";
    case "default_aws_graph_sub_account":
      return "Accounts";
    default:
      return "Overview";
  }
}

function GraphWithDrilldown({
  data,
  module,
  granularity,
  numberOfSeriesDisplayed = 20,
}: {
  data: any;
  module: string;
  granularity: string;
  numberOfSeriesDisplayed: number;
}) {
  const chartComponent = useRef(null);
  const series = data;

  // Update initial series with the color and borderColor
  const filteredSeries = (series?.initial || [])
    .filter((s) => s.t > 0)
    .sort((a, b) => b.t - a.t)
    .map((s, i) => {
      const colorIndex = i % COLORS.length; // Ensure the index stays within bounds of COLORS array
      s.color = `${COLORS[colorIndex]}80`;
      s.borderColor = COLORS[colorIndex];
      return s;
    });

  const initialSeries = filteredSeries
    .slice(0, numberOfSeriesDisplayed)
    .reverse();

  const defaultAvailableInitialSeries = filteredSeries.slice(
    numberOfSeriesDisplayed,
  );
  const [availableInitialSeries, setAvailableInitialSeries] = React.useState(
    defaultAvailableInitialSeries,
  );
  const [extraInitialSeries, setExtraInitialSeries] = React.useState([]);

  React.useEffect(() => {
    if (data === undefined) {
      return;
    }
    setAvailableInitialSeries(defaultAvailableInitialSeries);
    setOptions(getInitialOptions());
  }, [data]);

  const defaultDrilldownSteps = [
    { id: "initial", name: getInitialSeriesName(module) },
  ];
  const [drilldownSteps, setDrilldownSteps] = React.useState(
    defaultDrilldownSteps,
  );
  const isInitialSeries =
    drilldownSteps.length === 1 && drilldownSteps[0].id === "initial";
  const [otherAvailableSeries, setOtherAvailableSeries] = React.useState([]);
  const [extraSeries, setExtraSeries] = React.useState([]);

  const dateOptions = {
    timeZone: "UTC",
    year: "numeric",
    month: "long",
  };

  if (granularity === "daily" || granularity === "weekly") {
    dateOptions["day"] = "numeric";
  }

  const handleDrilldown = (pointOptions) => {
    const { id, name, drilldown } = pointOptions;
    if (drilldown === false || drilldown === undefined) return;

    handleDrillEvent(id);

    setDrilldownSteps((drilldownSteps) => [
      ...drilldownSteps,
      ...[{ id, name }],
    ]);
  };

  const handleDrillup = (seriesId, drilldownStepIndex) => {
    if (seriesId === "initial") {
      setOptions((currentOptions) => ({
        ...currentOptions,
        series: initialSeries,
      }));
      setDrilldownSteps(defaultDrilldownSteps);
      setAvailableInitialSeries(defaultAvailableInitialSeries);
      setExtraInitialSeries([]);
    } else {
      handleDrillEvent(seriesId);
      setDrilldownSteps((drilldownSteps) =>
        drilldownSteps.slice(0, drilldownStepIndex),
      );
    }
  };

  const handleDrillEvent = (seriesId) => {
    const allNewSeries = series.drilldown[seriesId]
      .filter((s) => s.t > 0)
      .sort((a, b) => b.t - a.t);

    const topNewSeries = allNewSeries
      .slice(0, numberOfSeriesDisplayed)
      .reverse()
      .map((s, i) => {
        const colorIndex = i % COLORS.length; // Ensure the index stays within bounds of COLORS array
        s.color = `${COLORS[colorIndex]}80`;
        s.borderColor = COLORS[colorIndex];
        return s;
      });

    setOptions((currentOptions) => ({
      ...currentOptions,
      series: topNewSeries,
    }));

    setExtraSeries([]);

    setOtherAvailableSeries(
      allNewSeries.slice(numberOfSeriesDisplayed).reverse(),
    );
  };

  function getInitialOptions() {
    return {
      boost: {
        useGPUTranslations: true,
      },
      tooltip: {
        split: false,
        headerFormat: "",
        pointFormatter: function () {
          const header = new Date(this.x).toLocaleString("en-US", dateOptions);
          const currentValue = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(Number(this.y));

          const previousValue = this.series.points.find(
            (point) => point.index == this.index - 1,
          )?.y;
          let delta = "";
          if (previousValue && previousValue > 0) {
            const deltaValue = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(Number(this.y - previousValue));
            const deltaPercentage =
              (100 * (this.y - previousValue)) / previousValue;
            delta = ` (Δ ${deltaValue}; ${deltaPercentage.toFixed(2)}%)`;
          }

          return `${header}<br/><span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${currentValue}</b>${delta}<br/>`;
        },
      },
      chart: {
        type: "column",
        zooming: {
          mouseWheel: {
            enabled: false,
          },
        },
        panning: false,
      },
      scrollbar: {
        enabled: false,
      },
      xAxis: {
        minRange: 1,
      },
      yAxis: {
        stackLabels: {
          enabled: true,
          style: {
            color: "gray",
            textOutline: "none",
          },
          formatter: function () {
            return new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(Number(this.total));
          },
        },
      },
      navigator: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: "normal",
        },
        series: {
          padding: 0,
          groupPadding: 0,
          showInNavigator: true,
          point: {
            events: {
              click: function (e) {
                handleDrilldown(e.point.options);
              },
            },
          },
        },
      },
      legend: {
        enabled: true,
        reversed: true,
      },
      rangeSelector: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      drilldown: {
        activeAxisLabelStyle: {
          cursor: "none",
          color: "#808080",
          "text-decoration": "none",
          "pointer-events": "none",
        },
      },
      series: initialSeries,
    };
  }

  const [options, setOptions] = React.useState(getInitialOptions());

  React.useEffect(() => {
    Boost(Highcharts);
    drilldown(Highcharts);
  }, []);

  return (
    <div className="relative w-full" id={`chart-${module}`}>
      <div className="flex space-x-2">
        {drilldownSteps.map((step, stepIndex) => (
          <div key={step.id} className="flex space-x-2">
            {stepIndex !== drilldownSteps.length - 1 ? (
              <>
                <button
                  type={"button"}
                  className="text-blue-700 text-md font-medium"
                  onClick={() => handleDrillup(step.id, stepIndex + 1)}
                >
                  {step.name}
                </button>
                <div className="text-gray-400 font-medium">/</div>
              </>
            ) : (
              <span className="text-gray-700 text-md font-medium">
                {step.name}
              </span>
            )}
          </div>
        ))}
      </div>
      <HighchartsReact
        options={options}
        highcharts={Highcharts}
        constructorType={"stockChart"}
        ref={chartComponent}
        containerProps={{ className: "h-[50vh]" }}
      />
      <div className="flex justify-evenly">
        <button
          className="px-2 py-1 text-xs bg-gray-100 text-[#333333] hover:bg-gray-200 hover:text-gray-900"
          onClick={() => {
            const chart = chartComponent.current?.chart;
            const series = chart.series;
            series.forEach((s) => {
              if (s.visible) {
                s.setVisible(false, false);
              }
            });
            chart.redraw();
          }}
        >
          Deselect All
        </button>
        <button
          className="px-2 py-1 text-xs bg-gray-100 text-[#333333] hover:bg-gray-200 hover:text-gray-900"
          onClick={() => {
            const chart = chartComponent.current?.chart;
            const series = chart.legend.allItems;
            series.slice(0, 10).forEach((s) => {
              s.setVisible(true, true);
            });
            series.slice(10).forEach((s) => {
              s.setVisible(false, false);
            });
            chart.redraw();
          }}
        >
          Select Top 10
        </button>
        <button
          className="px-2 py-1 text-xs bg-gray-100 text-[#333333] hover:bg-gray-200 hover:text-gray-900"
          onClick={() => {
            const chart = chartComponent.current?.chart;
            const series = chart.legend.allItems;
            series.slice(0, 20).forEach((s) => {
              s.setVisible(true, true);
            });
            series.slice(20).forEach((s) => {
              s.setVisible(false, false);
            });
            chart.redraw();
          }}
        >
          Select Top 20
        </button>
      </div>
      {isInitialSeries && availableInitialSeries.length > 0 ? (
        <OtherSeries
          numberOfSeriesDisplayed={numberOfSeriesDisplayed}
          extraSeries={extraInitialSeries}
          setExtraSeries={setExtraInitialSeries}
          otherAvailableSeries={availableInitialSeries}
          setOtherAvailableSeries={setAvailableInitialSeries}
          setOptions={setOptions}
        />
      ) : null}
      {otherAvailableSeries.length > 0 ? (
        <OtherSeries
          numberOfSeriesDisplayed={numberOfSeriesDisplayed}
          extraSeries={extraSeries}
          otherAvailableSeries={otherAvailableSeries}
          setExtraSeries={setExtraSeries}
          setOtherAvailableSeries={setOtherAvailableSeries}
          setOptions={setOptions}
        />
      ) : null}
    </div>
  );
}

function OtherSeries({
  numberOfSeriesDisplayed,
  extraSeries,
  setExtraSeries,
  otherAvailableSeries,
  setOtherAvailableSeries,
  setOptions,
}) {
  const [query, setQuery] = React.useState("");

  const handleSelectExtraSeries = (value) => {
    if (extraSeries.find((s) => s.id === value.value)) {
      return;
    }
    const found = otherAvailableSeries.find((s) => s.id === value.value);
    setExtraSeries((extraSeries) => extraSeries.concat([found]));
    setOtherAvailableSeries((otherAvailableSeries) =>
      otherAvailableSeries.filter((s) => s.id !== value.value),
    );
    setOptions((currentOptions) => ({
      ...currentOptions,
      series: currentOptions.series.concat([found]),
    }));
  };

  const selectableExtraSeries =
    query === ""
      ? otherAvailableSeries
      : otherAvailableSeries.filter((item) => {
          return (
            item.id.toLowerCase().includes(query.toLowerCase()) ||
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        });

  return (
    <div className="mt-4 text-center flex flex-col space-y-2">
      <div className="font-medium text-sm text-gray-600">
        We're displaying the top {numberOfSeriesDisplayed} items. To see another
        series, select it from the dropdown below:
      </div>
      <div>
        <Combobox
          as="div"
          onChange={(value) => {
            if (value) {
              handleSelectExtraSeries(value);
            }
          }}
          className="md:block w-full"
          nullable
        >
          <Combobox.Button as="div">
            <Combobox.Input
              className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-sky-600 focus:outline-none focus:ring-1 focus:ring-sky-600 sm:text-sm"
              placeholder="Select a resource"
              onChange={(event) => setQuery(event.target.value)}
            />
          </Combobox.Button>
          {otherAvailableSeries.length > 0 && (
            <VirtualCombobox
              items={selectableExtraSeries.map((s) => ({
                value: s.id,
                name: s.name,
              }))}
              itemContent={(index, item) => (
                <Combobox.Option
                  key={item.value}
                  value={item}
                  className={
                    "relative cursor-default select-none py-2 pl-8 pr-4"
                  }
                >
                  <span className={clsx("block truncate")}>{item.name}</span>
                </Combobox.Option>
              )}
            />
          )}
        </Combobox>
      </div>
    </div>
  );
}

export { GraphWithDrilldown };
