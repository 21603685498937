import * as React from "react";
import { Virtuoso } from "react-virtuoso";
import { Combobox } from "@headlessui/react";

const VirtualCombobox = ({
  items = [],
  itemContent = (item) => item,
  scrollerClassnames = "list-style-none absolute z-10 mt-1 max-h-60 w-auto overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
  listStyle = (items) => ({
    height: `${items.length * 36}px`,
    maxHeight: `${7 * 36}px`,
  }),
}) => {
  // do not inline the component, as a fresh instance would be created with each re-render
  // if you need to do some conditional logic, use Virtuoso's context prop to pass props inside the Scroller
  const Scroller = React.forwardRef(({ style, ...props }, ref) => {
    // an alternative option to assign the ref is
    // <div ref={(r) => ref.current = r}>
    return (
      <ul style={style} ref={ref} {...props} className={scrollerClassnames} />
    );
  });

  return (
    <Combobox.Options>
      <Virtuoso
        style={listStyle(items)}
        data={items}
        components={{ Scroller }}
        // overscan={100}
        itemContent={itemContent}
      />
    </Combobox.Options>
  );
};

export default VirtualCombobox;
