Date.prototype.toUTC = function() {
  return new Date(this.getTime() + this.getTimezoneOffset() * 60000);
};

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.toDayStr = function() {
  const offset = this.getTimezoneOffset();
  const yourDate = new Date(this.getTime() - (offset*60*1000));
  return yourDate.toISOString().split('T')[0]
};