import React from "react";
import SanitizedHTML from "react-sanitized-html";
import { renderToString, renderToStaticMarkup } from "react-dom/server";

export const ERROR_MESSAGE =
  'Something went wrong, sorry about that. Try again and please reach out to us at <a class="blue_link" href="mailto:hello@cloudforecast.io?Subject=Help!">hello@cloudforecast.io</a> if this problem persists.';

export function updateAlert(alertDivId, text, type, fade, timeout = 4000) {
  var _this = this;
  var div = document.getElementById(alertDivId);
  div.innerHTML = renderToString(
    <Alert alert={{ text: text, type: type, fade: fade }} />
  );
  if (fade) {
    window.setTimeout(function () {
      div.innerHTML = "";
    }, timeout);
  }
}

export function clearAlert(alertDivId) {
  document.getElementById(alertDivId).innerHTML = "";
}

class Alert extends React.Component {
  alertClass(type) {
    let classes = {
      error: "alert-danger",
      alert: "alert-warning",
      notice: "alert-info",
      info: "alert-info",
      success: "alert-success",
    };
    return classes[type] || classes.notice;
  }

  render() {
    const alert = this.props.alert;
    const alertClassName = `alert ${this.alertClass(alert.type)}`;

    return (
      <div className={alertClassName}>
        <button className="close" data-dismiss="alert">
          {" "}
          &times;{" "}
        </button>
        <SanitizedHTML html={alert.text} />
      </div>
    );
  }
}

export default Alert;
